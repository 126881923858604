import styled from 'styled-components'
import { Subheader } from '../../styles/shared'
import { ButtonSmall } from '../button/styled'
import { TagContainer } from '../tag/styled'

export const Container = styled.div`
  margin: 16px 0;
  border-radius: 2px;
  overflow: hidden;
`

export const Header = styled.div`
  background: ${({ theme }) => theme.palette.blue70};
  color: ${({ theme }) => theme.palette.blue40};
  padding-left: 16px;
  min-height: 34px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;

  ${TagContainer} {
    margin-right: 8px;
  }

  ${ButtonSmall} {
    margin: 0;
  }
`

export const Title = styled.span`
  ${Subheader}
  padding-right: 8px;
  letter-spacing: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Content = styled.span`
  margin: 8px 0;
  white-space: nowrap;
`
