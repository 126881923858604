import styled from 'styled-components'

export const Container = styled.div`
  code[class*='language-'],
  pre[class*='language-'] {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 14px;
    line-height: 1.5;

    max-height: 500px;
    overflow: auto;
  }

  /* Code blocks */
  pre[class*='language-'] {
    padding: 1em;
    margin: 0;
    overflow: auto;
  }

  /* Inline code */
  :not(pre) > code[class*='language-'] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }
`
