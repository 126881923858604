import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setMenuVisibility, toggleMenu } from '../../store/config'
import {
  CenterColumn,
  CloseMenuIcon,
  Container,
  Logo,
  LogoContainer,
  MenuButton,
  MenuContainer,
  OpenMenuIcon,
  RightColumn,
} from './styled'

type Props = {
  leftColumn?: JSX.Element
  centerColumn?: JSX.Element
  rightColumn?: JSX.Element
}

const Navbar: React.FC<Props> = (props) => {
  const { leftColumn, centerColumn, rightColumn } = props
  const { href } = useLocation()
  const isMenuOpen = useSelector((store) => store.config.isMenuOpen)
  const dispatch = useDispatch()
  const isCenterShown = !isMenuOpen && centerColumn

  const onMenuClick = () => {
    dispatch(toggleMenu())
  }

  useEffect(() => {
    dispatch(setMenuVisibility(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [href])

  return (
    <Container>
      <MenuContainer>
        <MenuButton onClick={onMenuClick}>
          {isMenuOpen ? <CloseMenuIcon /> : <OpenMenuIcon />}
        </MenuButton>
      </MenuContainer>
      <LogoContainer>
        <Link to="/">
          <Logo />
        </Link>
        {leftColumn}
      </LogoContainer>
      {isCenterShown && <CenterColumn>{centerColumn}</CenterColumn>}
      <RightColumn>{rightColumn}</RightColumn>
    </Container>
  )
}

export default Navbar
