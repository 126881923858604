import React from 'react'
import ArrowLink from '../arrow-link'
import {
  Container,
  Content,
  CopyrightSection,
  HiringSection,
  NavSection,
} from './styled'

const FooterLanding: React.FC = () => (
  <Container>
    <Content>
      <NavSection>
        <h5>Sitoo</h5>
        <ul>
          <li>
            <a href="https://www.sitoo.com/">Sitoo Home</a>
          </li>
          <li>
            <a href="https://www.sitoo.com/about-sitoo/">About</a>
          </li>
          <li>
            <a href="https://www.sitoo.com/about-sitoo/partners">Partners</a>
          </li>
          <li>
            <a href="https://www.sitoo.com/press/">News & Press</a>
          </li>
          <li>
            <a href="https://careers.sitoo.com/">Careers</a>
          </li>
          <li>
            <a href="https://www.sitoo.com/contact/">Contact</a>
          </li>
        </ul>
      </NavSection>
      <NavSection>
        <h5>Support</h5>
        <ul>
          <li>
            <a href="https://support.sitoo.se/hc/en-us">Help Desk</a>
          </li>
          <li>
            <a href="https://status.sitoo.com/">Platform Status</a>
          </li>
          <li>
            <a href="https://support.sitoo.se/hc/en-us/requests/new">
              Contact Support
            </a>
          </li>
          <li>
            <a href="https://support.sitoo.se/hc/en-us/sections/360000024509-Vanliga-fr%C3%A5gor-svar">
              FAQ
            </a>
          </li>
          <li>
            <a href="https://www.sitoo.com/terms-of-service/">
              Terms of Service
            </a>
          </li>
          <li>
            <a href="https://www.sitoo.com/privacy-notice/">Private Notice</a>
          </li>
        </ul>
      </NavSection>
      <NavSection>
        <h5>Follow Us</h5>
        <ul>
          <li>
            <a href="https://www.instagram.com/sitoo">Instagram</a>
          </li>
          <li>
            <a href="https://twitter.com/sitoo">Twitter</a>
          </li>
          <li>
            <a href="https://www.facebook.com/sitooab/">Facebook</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/sitoo-ab">Linkedin</a>
          </li>
        </ul>
      </NavSection>
      <HiringSection>
        <h5>We are hiring!</h5>
        <p>
          Like the idea of shaping the future of retail? Want to work with new
          infrastructure and cutting edge technology? Love cracking complex
          challenges? No need to look any further.
        </p>
        <ArrowLink to="https://careers.sitoo.com/">Apply now</ArrowLink>
      </HiringSection>
      <CopyrightSection>
        Copyright © {new Date().getFullYear()} Sitoo AB. All rights reserved.
      </CopyrightSection>
    </Content>
  </Container>
)

export default FooterLanding
