import React from 'react'
import { WrapPageElementBrowserArgs } from 'gatsby'
import { DataProvider, LayoutProvider } from './src/providers'
import { Helmet } from 'react-helmet'
import Layout from './src/layouts'

/**  @param {WrapPageElementBrowserArgs} */
export const wrapRootElement = ({ element }) => {
  return <DataProvider>{element}</DataProvider>
}

const NoIndex = (
  <Helmet>
    <meta name="robots" content="noindex, nofollow" />
  </Helmet>
)

const TrackingHubspot = (
  <Helmet>
    {/* <!-- Start of HubSpot Embed Code --> */}
    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/5441101.js"></script>
    {/* <!-- End of HubSpot Embed Code --> */}
  </Helmet>
)

/**  @param {WrapPageElementBrowserArgs<string, { layout: string }>} */
export const wrapPageElement = ({ element, props }) => {
  const { layout } = props.pageContext
  const isProduction = process.env.GATSBY_BRANCH === 'master'


  return (
    <LayoutProvider>
      {!isProduction && NoIndex}
      {TrackingHubspot}
      <Layout type={layout}>
        {element}
      </Layout>
    </LayoutProvider>
  )
}
