import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import Navbar from '../../components/navbar'
import Sidebar from '../../components/sidebar'
import Footer from '../../components/footer'
import Categories, {
  SidebarCategory,
} from '../../components/sidebar/categories'
import SearchBar from '../../components/searchbar'
import { useInterval } from '../../hooks/interval'
import { removeTrailingSlash } from '../../utils/format'
import { useHydration } from '../../hooks/hydration'
import { Content, Container, ContentContainer } from './styled'

type SidebarData = {
  allCategory: {
    nodes: SidebarCategory[]
  }
}

const CenterColumn = <SearchBar />

const Docs: React.FC = (props) => {
  const { children } = props
  const { allCategory } = useStaticQuery<SidebarData>(
    graphql`
      query SidebarData {
        allCategory {
          nodes {
            name
            slug
            type
            url
            categories {
              name
              slug
              type
              filename
              url
              categories {
                name
                slug
                filename
                type
                url
                categories {
                  name
                  slug
                  filename
                  type
                  url
                }
              }
            }
          }
        }
      }
    `,
  )

  const { key } = useHydration()
  const { pathname, hash } = useLocation()
  const pathnameWithoutTrailingSlash = removeTrailingSlash(pathname)
  const [location, setLocation] = useState<{ pathname: string; hash: string }>({
    pathname: pathnameWithoutTrailingSlash,
    hash,
  })

  const categories = allCategory.nodes

  useEffect(() => {
    if (
      pathnameWithoutTrailingSlash !== location.pathname ||
      hash !== location.hash
    ) {
      setLocation({ pathname: pathnameWithoutTrailingSlash, hash })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathnameWithoutTrailingSlash, hash])

  //    We need to use interval because the the scrollToAnchor is using history.push
  // to make a manual scroll animation to the hash.
  useInterval(() => {
    if (window.location.hash !== location.hash) {
      setLocation((p) => ({ ...p, hash: window.location.hash }))
    }
  }, 300)

  return (
    <Container>
      <Navbar centerColumn={CenterColumn} />
      <ContentContainer>
        <Sidebar>
          <Categories
            categories={categories}
            pathname={location.pathname}
            hash={location.hash}
            key={key}
          />
        </Sidebar>
        <Content>{children}</Content>
      </ContentContainer>
      <Footer />
    </Container>
  )
}

export default Docs
