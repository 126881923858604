import React from 'react'
import { scrollToHash } from '../../../utils/scroll-to-hash'
import Category from '../category'
import { PageLink } from './styled'

export type SidebarCategory = {
  name: string
  slug: string
  type: 'api' | 'doc' | 'anchor' | 'dir'
  url: string
  categories?: SidebarCategory[]
}

type Props = {
  categories: SidebarCategory[]
  slug?: string
  pathname: string
  hash: string
}

const isCategoryActive = (
  pathname: string,
  hash: string,
  currentUrl: string,
) => {
  const isActive =
    `${pathname}${hash}` === currentUrl ||
    pathname === currentUrl ||
    pathname.includes(`${currentUrl}/`)

  const isObjectsPage = `${pathname}#objects` === currentUrl

  if (isActive || !(isObjectsPage && hash)) return isActive

  const isHashAnObject = !!document.querySelectorAll(
    `.objects [id="${hash.replace('#', '')}"]`,
  ).length

  return isHashAnObject
}

const Categories: React.FC<Props> = (props) => {
  const { categories, pathname, hash } = props
  const location = `${pathname}${hash}`

  const checkIfCategoryIsActive = (
    currentCategory: SidebarCategory,
    childCategories: SidebarCategory[],
  ) => {
    return (
      isCategoryActive(pathname, hash, currentCategory.url) ||
      (currentCategory.type === 'anchor' &&
        childCategories.length > 0 &&
        childCategories.some((c) => isCategoryActive(pathname, hash, c.url)))
    )
  }

  const checkIfLinkIsActive = (path: string) => {
    const locationPath = path.includes('#') ? location : pathname
    return locationPath === path
  }

  return (
    <>
      {categories.map((category) => {
        const { name, categories, url } = category
        return categories ? (
          <Category
            key={url}
            category={name}
            name={name}
            isActive={checkIfCategoryIsActive(category, categories)}
          >
            <Categories
              categories={categories}
              slug={url}
              pathname={pathname}
              hash={hash}
            />
          </Category>
        ) : (
          <PageLink
            key={url}
            to={url}
            onClick={scrollToHash}
            className={checkIfLinkIsActive(url) ? 'active' : undefined}
          >
            {name}
          </PageLink>
        )
      })}
    </>
  )
}

export default Categories
