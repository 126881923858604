import { useEffect, useState } from 'react'

export const useHydration = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => setIsClient(true), [])

  return {
    key: isClient ? 'client' : 'server',
  }
}
