import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleMenu } from '../../store/config'
import { Container, Overlay, ScrollContainer } from './styled'

const Sidebar: React.FC = (props) => {
  const isMenuOpen = useSelector((store) => store.config.isMenuOpen)
  const dispatch = useDispatch()

  const onOverlayClick = () => {
    dispatch(toggleMenu())
  }

  return (
    <>
      <Overlay
        onClick={onOverlayClick}
        className={isMenuOpen ? 'open' : undefined}
      />
      <Container className={isMenuOpen ? 'open' : undefined}>
        <ScrollContainer>{props.children}</ScrollContainer>
      </Container>
    </>
  )
}

export default Sidebar
