// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-templates-mdx-index-tsx": () => import("./../../../src/templates/mdx/index.tsx" /* webpackChunkName: "component---src-templates-mdx-index-tsx" */),
  "component---src-templates-openapi-index-tsx": () => import("./../../../src/templates/openapi/index.tsx" /* webpackChunkName: "component---src-templates-openapi-index-tsx" */)
}

