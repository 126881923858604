import React from 'react'
import {
  WalkthroughStepTitle,
  WalkthroughStepContainer,
  WalkthroughStepNumber,
  WalkthroughStepContent,
  WalkthroughStepNumberContent,
  ProgressDoneIcon,
} from './styled'

type WalkthroughGuideStepProps = {
  children: string
  title: string
  step?: string
  done?: boolean
}

const WalkthroughGuideStep: React.FC<WalkthroughGuideStepProps> = (props) => {
  const { children, title, step = '-', done = false } = props
  //   const variant = deprecated ? 'gray' : getTagColor(children)

  return (
    <WalkthroughStepContainer>
      <WalkthroughStepNumber className="number">
        <WalkthroughStepNumberContent>
          {done ? <ProgressDoneIcon></ProgressDoneIcon> : step}
        </WalkthroughStepNumberContent>
      </WalkthroughStepNumber>
      <WalkthroughStepTitle className="border-sm">{title}</WalkthroughStepTitle>
      <WalkthroughStepContent className="border">
        {children}
      </WalkthroughStepContent>
    </WalkthroughStepContainer>
  )
}

export default WalkthroughGuideStep
