import { Theme } from '../../styles/theme'

export const getThemeColorByName = (
  palette: Theme['palette'],
  color: string,
  type?: number,
) => {
  const name = `${color}${type || 'Base'}`

  if (name in palette) {
    const themeColor = palette[name]
    if (typeof themeColor === 'string') return themeColor
  }
}
