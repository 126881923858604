import React from 'react'
import { MDXProvider as Provider } from '@mdx-js/react'
import Banner from '../../components/banner'
import Code from '../../components/code'
import GatsbyLink from '../../components/link'
import Image from '../../components/image'
import SimpleTable from '../../components/simple-table'
import Tag from '../../components/tag'
import Verb from '../../components/verb'
import WalkthroughGuide from '../../components/walkthrough-guide'
import WalkthroughGuideStep from '../../components/walkthrough-guide-step'

const Pre: React.FC = (props) => <div {...props} />

const Link: React.FC<{ href: string }> = ({ href, ...props }) => (
  <GatsbyLink to={href} {...props} />
)

const components = {
  Banner,
  pre: Pre,
  code: Code,
  a: Link,
  img: Image,
  table: SimpleTable,
  Tag,
  Verb,
  WalkthroughGuide,
  WalkthroughGuideStep,
}

const MDXProvider: React.FC = ({ children }) => (
  <Provider components={components}>{children}</Provider>
)

export default MDXProvider
