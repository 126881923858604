import React from 'react'
import {
  WalkthroughContainer,
  WalkthroughSteps,
  WalkthroughTitle,
} from './styled'

type WalkthroughGuideProps = {
  children: string
  title: string
}

const WalkthroughGuide: React.FC<WalkthroughGuideProps> = (props) => {
  const { children, title } = props

  return (
    <WalkthroughContainer>
      <WalkthroughTitle>{title}</WalkthroughTitle>
      <WalkthroughSteps>{children}</WalkthroughSteps>
    </WalkthroughContainer>
  )
}

export default WalkthroughGuide
