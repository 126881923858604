import * as React from 'react'
import { Container, Content, InfoIcon, Title } from './styled'

type BannerType = 'warning' | 'note' | 'error'

const colorMap = {
  warning: 'orange',
  note: 'blue',
  error: 'red',
}

type Props = {
  title: string
  type: BannerType
}

const Banner: React.FC<Props> = (props) => {
  const { children, title, type } = props
  const color = colorMap[type]

  return (
    <Container color={color}>
      <InfoIcon color={color} />
      <Content>
        <Title>{title}</Title>
        {children}
      </Content>
    </Container>
  )
}

export default Banner
