import styled from 'styled-components'
import { Body02 } from '../../styles/shared'
import ProgressDone from '../../assets/progress-done.svg'

export const ProgressDoneIcon = styled(ProgressDone)`
  fill: ${({ theme }) => theme.palette.greenBase};
  background: ${({ theme }) => theme.palette.blue80};
`

export const WalkthroughStepContainer = styled.div`
  background: ${({ theme }) => theme.palette.blue80};
  color: ${({ theme }) => theme.palette.white};
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;

  display: grid;
  grid: auto / 38px 132px auto;
  grid-template-areas: 'number title content';

  align-items: stretch;
  justify-content: stretch;

  .border,
  .border-sm {
    padding-bottom: 8px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.blue70};
  }

  &:last-child .border,
  &:last-child .border-sm {
    border-bottom: 0;
  }

  .number:before {
    content: '';
    border-left: 1px solid ${({ theme }) => theme.palette.blue70};
    position: absolute;
    top: 0;
    left: 9.75px;
    height: 100%;
    z-index: 0;
  }

  &:not(:last-child):not(:first-child) .number:before {
    top: -8px;
    height: calc(100% + 8px);
  }

  &:last-child .number:before {
    top: -8px;
    height: 8px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid: auto auto / 38px auto;
    grid-template-areas:
      'number title'
      'number content';

    .border-sm {
      border-bottom: 0;
    }
  }
`

export const WalkthroughStepNumber = styled.div`
  grid-area: number;
  position: relative;
`

export const WalkthroughStepNumberContent = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 1;
  position: relative;

  background: ${({ theme }) => theme.palette.blue40};
  color: ${({ theme }) => theme.palette.blue80};
`

export const WalkthroughStepTitle = styled.div`
  ${Body02}
  white-space: pre-line;
  grid-area: title;
  line-height: initial;
  background: ${({ theme }) => theme.palette.blue80};
  color: ${({ theme }) => theme.palette.white};

  padding-right: 16px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-right: 0;
  }
`

export const WalkthroughStepContent = styled.div`
  grid-area: content;
  overflow: auto;
  white-space: pre-line;

  .prism-code {
    box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.palette.blue70} inset;
  }
`
