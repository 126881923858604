import styled from 'styled-components'
import { Body01 } from '../../styles/shared'
import Search from '../../assets/search.svg'

export const Container = styled.div`
  position: relative;
`

export const SearchInput = styled.input`
  ${Body01}
  width: 100%;
  height: 40px;
  padding: 0 10px 0 44px;
  background-color: ${({ theme }) => theme.palette.gray10};
  border: none;
`

export const SearchIcon = styled(Search)`
  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 16px;
`
