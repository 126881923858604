import React from 'react'
import { Container, Copyright, Nav, NavLink } from './styled'

const Footer: React.FC = () => {
  const year = new Date().getFullYear()

  return (
    <Container>
      <Nav>
        <NavLink to="https://sitoo.com/about">About</NavLink>
        <NavLink to="https://sitoo.com/contact">Contact</NavLink>
        <NavLink to="https://support.sitoo.se">Help Desk</NavLink>
        <NavLink to="https://support.sitoo.se">Support</NavLink>
        <NavLink to="https://support.sitoo.se">FAQ</NavLink>
        <NavLink to="/sign-up">Sign Up</NavLink>
      </Nav>
      <Copyright>Copyright © {year} Sitoo AB. All rights reserved.</Copyright>
    </Container>
  )
}

export default Footer
