import React from 'react'
import Button from '../button'
import CodeHighlight from '../code-highlight'
import Verb from '../verb'
import { Container, Content, Header, Title } from './styled'

type Props = {
  children?: string | null
  className?: string
  headerContent?: string
  headerTag?: string
  showCopyButton?: boolean
  language?: string
}

const Code: React.FC<Props> = (props) => {
  const { children, className, showCopyButton, language: languageProp } = props
  const codeString = children || ''
  const language = className?.replace('language-', '') || languageProp || ''

  // parses beginning of a string inside 3 underscores, i.e. ___ content ___
  const headerRegexp = /^___\s+?(.+)\s+?___\s+/i

  // parses [[content]] inside two square brackets
  const tagRegexp = /^(\[\[(.+)\]\]\s?)?(.+)$/

  const headerResult = headerRegexp.exec(codeString)

  // remove header from the code string
  const codeWithoutHeader = headerResult?.[0]
    ? codeString.replace(headerResult[0], '')
    : codeString

  const code = codeWithoutHeader.trim()

  let headerContent =
    props.headerContent || headerResult?.[1]?.toLocaleUpperCase()

  // extract tag from the header string
  const [, , tag, restString] = headerContent?.match(tagRegexp) || []

  // if tag exists, use header w/o the tag
  headerContent &&= restString

  const headerTag = props.headerTag || tag

  const handleCopy = () => {
    void navigator.clipboard.writeText(code)
  }

  if (!language) {
    return <code>{code}</code>
  }

  return (
    <Container>
      {headerContent && (
        <Header>
          <Title>
            {headerTag && <Verb>{headerTag}</Verb>}
            <Content>{headerContent}</Content>
          </Title>
          {showCopyButton && (
            <Button size="small" onClick={handleCopy}>
              Copy
            </Button>
          )}
        </Header>
      )}
      {code && <CodeHighlight code={code} language={language} />}
    </Container>
  )
}

export default Code
