import React from 'react'
import Link from '../link'
import { ButtonBig, ButtonMedium, ButtonSmall } from './styled'

type Props = {
  size?: 'small' | 'medium' | 'big'
  variant?: 'blue' | 'green' | 'transparent'
  to?: string
  className?: string
  onClick?: React.MouseEventHandler
}

const ButtonComponent = {
  small: ButtonSmall,
  medium: ButtonMedium,
  big: ButtonBig,
}

const Button: React.FC<Props> = (props) => {
  const { className, children, to, onClick, variant = 'blue' } = props
  const size = props.size || 'medium'
  const Component = ButtonComponent[size]

  if (to) {
    return (
      <Component className={className} as={Link} to={to} variant={variant}>
        {children}
      </Component>
    )
  }

  return (
    <Component className={className} onClick={onClick} variant={variant}>
      {children}
    </Component>
  )
}

export default Button
