import { DefaultTheme, useTheme } from 'styled-components'

type MediaQueryMatcher = (theme: DefaultTheme) => string

type QueryInput = string | MediaQueryMatcher

const useMediaQuery = (queryInput: QueryInput): boolean => {
  const theme = useTheme()

  let query = typeof queryInput === 'function' ? queryInput(theme) : queryInput

  query = query.replace(/^@media( ?)/m, '')

  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined'

  return supportMatchMedia && window.matchMedia(query).matches
}

export default useMediaQuery
