import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider as Provider } from 'styled-components'
import { getThemeByName } from '../../styles/theme'

const ThemeProvider: React.FC = ({ children }) => {
  const themeName = useSelector((store) => store.config.themeName)
  const theme = getThemeByName(themeName)

  return <Provider theme={theme}>{children}</Provider>
}

export default ThemeProvider
