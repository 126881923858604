import * as React from 'react'
import { useTheme } from 'styled-components'
import { Theme } from '../../styles/theme'
import { TagContainer } from './styled'

type Variant = 'gray' | 'blue' | 'red' | 'strong-red' | 'orange' | 'green'

type Props = {
  variant?: Variant
}

const getStyles = (palette: Theme['palette'], variant?: Variant) => {
  switch (variant) {
    case 'blue':
      return { background: palette.blueBase, color: palette.white }
    case 'orange':
      return { background: palette.orange70, color: palette.orangeBase }
    case 'red':
      return { background: palette.red30, color: palette.red70 }
    case 'strong-red':
      return { background: palette.redBase, color: palette.white }
    case 'green':
      return { background: palette.green40, color: palette.green70 }
    case 'gray':
    default:
      return { background: palette.gray20, color: palette.gray70 }
  }
}

const Tag: React.FC<Props> = (props) => {
  const { children, variant } = props
  const { palette } = useTheme()

  return (
    <TagContainer {...getStyles(palette, variant)}>{children}</TagContainer>
  )
}

export default Tag
