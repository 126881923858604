import React from 'react'
import FooterLanding from '../../components/footer-landing'
import Navbar from '../../components/navbar'
import Sidebar from '../../components/sidebar'
import { Container, MenuContainer, MenuLink, SignUpButton } from './styled'

const Menu = (
  <MenuContainer>
    <MenuLink to="/guides/overview">Guides</MenuLink>
    <MenuLink to="/api-reference/overview">API Reference</MenuLink>
    <SignUpButton to="/sign-up" variant="green" size="big">
      Sign Up
    </SignUpButton>
  </MenuContainer>
)

const Landing: React.FC = (props) => {
  return (
    <Container>
      <Navbar rightColumn={Menu} />
      <Sidebar>{Menu}</Sidebar>
      {props.children}
      <FooterLanding />
    </Container>
  )
}

export default Landing
