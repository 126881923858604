import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import useMediaQuery from '../../../utils/use-media-query'
import ExpandIcon from '../../expand-icon'
import { SidebarCategory } from '../categories'
import {
  Container,
  CategoryLink,
  LinkList,
  LinkContainer,
  CollapseLink,
} from './styled'

export type CategoryNode = {
  id: string
  order?: number | null
  category?: string | null
  title?: string
  slug: string | null
}

type Props = {
  category: string | null
  name: string
  isActive: boolean
  children: React.ReactElement
}

const Category: React.FC<Props> = (props) => {
  const { name, children, isActive } = props
  const [isCollapsed, setCollapsed] = useState(!isActive)
  const collapsedClassName = isCollapsed ? 'collapsed' : ''
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const childrenProps = children.props as SidebarCategory | undefined
  const firstChildrenUrl = childrenProps?.categories?.[0]?.url

  // Updates a menu state if the category was selected programmatically
  useEffect(() => {
    setCollapsed(!isActive)
  }, [isActive])

  const toggleCollapsed = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setCollapsed(!isCollapsed)
  }

  const onCategoryClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const isNewTab = e.metaKey || e.ctrlKey

    if (!isNewTab) {
      toggleCollapsed(e)

      if (isCollapsed && firstChildrenUrl && !isMobile) {
        void navigate(firstChildrenUrl)
      }
    }
  }

  return (
    <Container>
      <LinkContainer>
        <CategoryLink href={firstChildrenUrl} onClick={onCategoryClick}>
          {name}
        </CategoryLink>
        <CollapseLink href={firstChildrenUrl} onClick={toggleCollapsed}>
          <ExpandIcon isExpanded={!isCollapsed} />
        </CollapseLink>
      </LinkContainer>
      <LinkList className={collapsedClassName}>{children}</LinkList>
    </Container>
  )
}

export default Category
