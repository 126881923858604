/**
 * Extracts hash from a target link and scroll a view to the element with id
 * which equal to the hash.
 */
export const scrollToHash = (event: React.MouseEvent<HTMLAnchorElement>) => {
  const { hash, href, pathname } = event.currentTarget
  const isNewTab = event.metaKey || event.ctrlKey
  const isSamePath = pathname === window.location.pathname

  if (hash && isSamePath && !isNewTab) {
    const elementId = hash.replace('#', '').toLowerCase()
    const element = document.getElementById(elementId)

    event.preventDefault()

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
      window.history.pushState({}, '', href)
    }
  }
}
