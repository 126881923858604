import styled from 'styled-components'
import { Body01, Body02 } from '../../styles/shared'
import Info from '../../assets/info.svg'
import { getThemeColorByName } from '../../utils/get-color'

type ContainerProps = {
  color: string
}

export const Container = styled.div<ContainerProps>`
  ${Body01}
  border: 1px solid;
  border-color: ${(props) =>
    getThemeColorByName(props.theme.palette, props.color, 40)};
  background: ${(props) =>
    getThemeColorByName(props.theme.palette, props.color, 20)};
  padding: 16px;
  margin: 16px 0;
  display: flex;
  flex-flow: row nowrap;
`

export const Title = styled.div`
  ${Body02}
`

export const Content = styled.div`
  color: ${({ theme }) => theme.palette.gray80};
`

type IconProps = {
  color: string
}

export const InfoIcon = styled(Info)<IconProps>`
  margin-right: 16px;
  flex-shrink: 0;
  fill: ${(props) => getThemeColorByName(props.theme.palette, props.color, 70)};
`
