import React from 'react'
import Docs from './docs'
import Landing from './landing'

type Props = {
  type: 'docs' | 'landing'
}

const Layout: React.FC<Props> = (props) => {
  const { children, type } = props
  const Component = type === 'docs' ? Docs : Landing

  return <Component>{children}</Component>
}

export default Layout
