import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

type Props = {
  to: string
  className?: string
  id?: string
}

/**
 * Gatsby.Link is suitable only for local links, so we should use <a href> for
 * external ones
 */
const Link: React.FC<Props> = (props) => {
  const { to, children, ...otherProps } = props
  const isExternal = /^http?s:\/\//.exec(to)
  // there are links in markdown which looks like `foo`,
  // but they should lead to their parent `../foo`
  const isRelative = /^\w/.exec(to)

  if (isExternal || !to) {
    return (
      <a href={to} {...otherProps}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={isRelative ? `../${to}` : to} {...otherProps}>
      {children}
    </GatsbyLink>
  )
}

export default Link
