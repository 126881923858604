import React from 'react'
import Highlight, { defaultProps, Language, Prism } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/vsDark'
import { useTheme } from 'styled-components'
import { Theme } from '../../styles/theme'
import { Container } from './styled'

type Props = {
  language: string
  code: string
}

const JSON_REQUEST = 'json-request' as Language
const JSON_RESPONSE = 'json-response' as Language

const getCustomStyles = (palette: Theme['palette']) => ({
  types: ['method'],
  style: { color: palette.orangeBase },
  languages: [JSON_REQUEST],
})

function initCustomRules() {
  const jsonLang = Prism.languages.json

  if (!Prism.languages[JSON_REQUEST]) {
    Prism.languages[JSON_REQUEST] = {
      ...jsonLang,
      method: /^[A-z]+/,
      url: {
        greedy: true,
        pattern: /\S+/,
      },
    }
  }

  if (!Prism.languages[JSON_RESPONSE]) {
    Prism.languages[JSON_RESPONSE] = jsonLang
  }
}

const CodeHighlight: React.FC<Props> = (props) => {
  const { code, language } = props
  const { palette } = useTheme()
  const plain = { backgroundColor: palette.blue80, color: palette.blue40 }
  const styles = [...theme.styles, getCustomStyles(palette)]
  const codeTheme = { ...theme, plain, styles }

  initCustomRules()

  return (
    <Container>
      <Highlight
        {...defaultProps}
        code={code}
        language={language as Language}
        theme={codeTheme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <div className="gatsby-highlight" data-language={language}>
            <pre className={className} style={style}>
              {tokens.map((line, index) => (
                <div {...getLineProps({ line })} key={index}>
                  {line.map((token, key) => (
                    <span {...getTokenProps({ token })} key={key} />
                  ))}
                </div>
              ))}
            </pre>
          </div>
        )}
      </Highlight>
    </Container>
  )
}

export default CodeHighlight
