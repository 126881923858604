import styled from 'styled-components'
import ChevronIcon from '../../assets/chevron-small-up.svg'

export const Icon = styled(ChevronIcon)`
  fill: currentColor;
  flex-shrink: 0;
  transform: rotate(180deg);
  display: block;

  &.expanded {
    transform: rotate(0deg);
  }
`
