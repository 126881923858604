import styled from 'styled-components'
import { Caption } from '../../styles/shared'

type Props = {
  background: string
  color: string
}

export const TagContainer = styled.span<Props>`
  ${Caption}
  text-transform: uppercase;
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 2px;
  padding: 2px 8px;
  margin: 8px 0px;
`
