import React from 'react'
import { StyledLink, RightArrow } from './styled'

type Props = {
  to: string
}

const ArrowLink: React.FC<Props> = (props) => {
  const { to, children } = props

  return (
    <StyledLink to={to}>
      {children} <RightArrow />
    </StyledLink>
  )
}

export default ArrowLink
