import React from 'react'
import { Icon } from './styled'

type Props = {
  isExpanded: boolean
}

const ExpandIcon: React.FC<Props> = (props) => {
  const className = props.isExpanded ? 'expanded' : ''

  return <Icon className={className} />
}

export default ExpandIcon
