import { Link } from 'gatsby'
import styled from 'styled-components'
import Button from '../../components/button'
import { Container as SidebarContainer } from '../../components/sidebar/styled'
import { Body02 } from '../../styles/shared'

export const Container = styled.div`
  ${({ theme }) => theme.breakpoints.up('md')} {
    ${SidebarContainer} {
      display: none;
    }
  }
`

export const MenuContainer = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    flex-flow: column;
    align-items: flex-start;
  }
`

export const SignUpButton = styled(Button)`
  margin-right: 0;
  margin-left: 16px;
`

export const MenuLink = styled(Link)`
  ${Body02};
  margin: 0px 16px;
  color: ${({ theme }) => theme.palette.gray80};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 10px 10px;
  }
`
