import { createGlobalStyle } from 'styled-components'
import {
  Caption,
  DisplayExtraLarge,
  DisplayLarge,
  DisplayMedium,
  DisplaySmall,
  Heading,
  Subheader,
} from './shared'

const GlobalStyles = createGlobalStyle`
  html, body {
    font-size: 16px;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.background.dimmed};
    -webkit-font-smoothing: antialiased;
  }

  button, input, select, textarea {
    font-size: 16px;
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  * {
    box-sizing: border-box;
  }

  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  }

  :focus {
    outline: ${({ theme }) => theme.palette.blueBase} auto 5px;
  }


  h1 {
    ${DisplayExtraLarge}
    margin-top: 0;
    margin-bottom: 0.35em;
  }
  
  h2 {
    ${DisplayLarge}
    margin-top: 0;
    margin-bottom: 0.35em;
  }

  h3 {
    ${DisplayMedium}
    margin-top: 0;
    margin-bottom: 0.35em;
  }

  h4 {
    ${DisplaySmall}
    margin-top: 0;
    margin-bottom: 0.35em;
  }

  h5 {
    ${Heading}
    margin-top: 0;
    margin-bottom: 0.35em;
  }

  h6 {
    ${Subheader}
    margin-top: 0;
    margin-bottom: 0.35em;
  }

  h1, h2, h3, h4, h5, h6  {
    & + p {
      margin-top: 0;
    }
  }

  p {
    line-height: 1.5;
  }

  a {
    color: ${({ theme }) => theme.palette.blueBase};
    text-decoration: none;
    
    &:hover {
      color: ${({ theme }) => theme.palette.blue40};
    }
  }

  li:not(:last-child) {
    margin-bottom: 0.25em;
  }

  code {
    background-color: ${({ theme }) => theme.palette.gray20};
    border: solid 1px ${({ theme }) => theme.palette.gray30};
    padding: 0px 2px;
    font-family: inherit;
  }

  blockquote {
    background-color: ${({ theme }) => theme.palette.background.default};
    padding: 12px;
    margin: 0;

    & > :last-child {
      margin-bottom: 0;
    }

    & > :first-child {
        margin-top: 0;
    }
  }

  hr {
    border-width: 1px;
    border-color: ${({ theme }) => theme.palette.gray30};
    border-style: solid;
    margin: 30px 0;
  }

  sup {
    ${Caption}
  }
  
  a[id] ,
  .anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
`

export default GlobalStyles
