import styled from 'styled-components'
import { Body02, Subheader } from '../../../styles/shared'

export const CategoryLink = styled.a`
  ${Subheader}
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.gray80};
  width: 100%;
`

export const Container = styled.div`
  margin: 10px 0;
`

export const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
  height: auto;
  display: block;
  visibility: visible;

  ${Container} {
    margin-top: 6px;
    padding-left: 8px;
  }

  ${CategoryLink} {
    ${Body02}
    text-transform: none;
    letter-spacing: normal;
  }

  & > a {
    padding-left: 8px;
  }

  &.collapsed,
  .collapsed & {
    display: none;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

export const CollapseLink = styled.a`
  padding-right: 8px;
  color: ${({ theme }) => theme.palette.gray80};
`
