import styled from 'styled-components'

export const SIDEBAR_WIDTH = 250

export const Container = styled.div`
  box-shadow: 1px 0 0 0 ${({ theme }) => theme.palette.gray30};
  background-color: ${({ theme }) => theme.palette.gray10};

  grid-area: sidebar;
  position: fixed;
  top: 0;
  bottom: 0;
  width: ${SIDEBAR_WIDTH}px;
  z-index: 10;

  user-select: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    left: -${SIDEBAR_WIDTH}px;

    &.open {
      left: 0;
    }
  }
`

export const ScrollContainer = styled.div`
  overflow-y: scroll;
  overflow-x: auto;

  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  padding-left: 24px;
  padding-top: 80px;
  padding-bottom: 160px;
  padding-right: 10px;
`

export const Overlay = styled.div`
  position: fixed;
  background: black;
  opacity: 0.2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    &.open {
      display: block;
    }
  }
`
