import React from 'react'
import Tag from '../tag'

const getTagColor = (tag: string) => {
  switch (tag.toLowerCase()) {
    case 'delete':
      return 'red'
    case 'put':
      return 'orange'
    case 'post':
      return 'green'
    case 'get':
    default:
      return 'blue'
  }
}

type Props = {
  children: string
  deprecated?: boolean
}

const Verb: React.FC<Props> = (props) => {
  const { children, deprecated = false } = props
  const variant = deprecated ? 'gray' : getTagColor(children)

  return <Tag variant={variant}>{children}</Tag>
}

export default Verb
