import { graphql, useStaticQuery } from 'gatsby'
import React, { CSSProperties } from 'react'
import { ImageQuery } from './__generated__/ImageQuery'

type Props = {
  src: string
  alt?: string
  width?: string | number
  height?: string | number
  style?: CSSProperties
}

const Image: React.FC<Props> = (props) => {
  const { src, alt = '', width, height, style } = props

  const response = useStaticQuery<ImageQuery>(
    graphql`
      query ImageQuery {
        allFile(filter: { ext: { in: [".png", ".svg", ".jpg", ".jpeg"] } }) {
          nodes {
            relativePath
            publicURL
          }
        }
      }
    `,
  )

  const image = response.allFile.nodes.find((n) => n.relativePath.endsWith(src))

  if (!image?.publicURL) return null

  return (
    <img
      src={image.publicURL}
      alt={alt}
      width={width}
      height={height}
      style={style}
    />
  )
}

export default Image
