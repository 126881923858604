import styled from 'styled-components'
import { Subheader } from '../../styles/shared'

export const WalkthroughContainer = styled.div``

export const WalkthroughTitle = styled.div`
  ${Subheader}
  text-transform: uppercase;
  background: ${({ theme }) => theme.palette.blue70};
  color: ${({ theme }) => theme.palette.blue40};

  padding: 10px 16px;
  min-height: 38px;
`
export const WalkthroughSteps = styled.div``
