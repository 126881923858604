import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.blue80};
`

export const Content = styled.div`
  max-width: 1280px;
  margin: auto;
  color: ${({ theme }) => theme.palette.white};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
  padding: 50px 40px;
  grid-gap: 32px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: 1fr;
  }
`

export const HiringSection = styled.section`
  a {
    color: ${({ theme }) => theme.palette.blue40};
  }

  a:hover {
    color: ${({ theme }) => theme.palette.white};
  }

  p {
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-row: 3 / 3;
    grid-column: 1 / 3;
  }
`

export const NavSection = styled.nav`
  ul {
    list-style: none;
    padding: 0;
    margin: 16px 0 0 0;
  }

  a {
    padding: 3px 0;

    :not(:hover) {
      color: ${({ theme }) => theme.palette.white};
    }
  }
`

export const CopyrightSection = styled.section`
  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-column: 1 / 5;
    margin-top: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-row: 4 / 4;
    grid-column: 1 / 3;
  }
`
