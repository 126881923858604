import * as React from 'react'
import { Helmet } from 'react-helmet'
import GlobalStyles from '../styles/global'
import MDXProvider from './mdx'
import StoreProvider from './store'
import ThemeProvider from './theme'

export const DataProvider: React.FC = ({ children }) => {
  return (
    <StoreProvider>
      <ThemeProvider>
        <MDXProvider>{children}</MDXProvider>
      </ThemeProvider>
    </StoreProvider>
  )
}

export const LayoutProvider: React.FC = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/vcm8vic.css" />
      </Helmet>
      <GlobalStyles />
      {children}
    </>
  )
}
