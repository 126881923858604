import styled, { css } from 'styled-components'
import { Body01, Body02 } from '../../styles/shared'

type ButtonProps = { variant?: string }

export const ButtonBase = css`
  border: none;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: 8px;
`

export const TransparentVariant = css`
  color: ${({ theme }) => theme.palette.blueBase};
  background-color: transparent;

  &:hover {
    color: ${({ theme }) => theme.palette.blue60};
  }
`

export const BlueVariant = css`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.blueBase};

  &:hover {
    color: ${({ theme }) => theme.palette.white};
    background-color: ${({ theme }) => theme.palette.blue60};
  }
`

export const GreenVariant = css`
  color: ${({ theme }) => theme.palette.white};
  background-color: ${({ theme }) => theme.palette.greenBase};

  &:hover {
    color: ${({ theme }) => theme.palette.white};
    background-color: #56ab8f;
  }
`

export const ColorVariant = css<ButtonProps>`
  ${({ variant }) => variant === 'blue' && BlueVariant}
  ${({ variant }) => variant === 'green' && GreenVariant}
  ${({ variant }) => variant === 'transparent' && TransparentVariant}
`

export const FontVariant = css<ButtonProps>`
  text-transform: ${({ variant }) =>
    variant === 'transparent' ? 'none' : 'uppercase'};
`

export const ButtonSmall = styled.button<ButtonProps>`
  ${ButtonBase}
  ${ColorVariant}
  ${FontVariant}
  ${Body01}
  padding: 5px 10px;
`

export const ButtonMedium = styled.button<ButtonProps>`
  ${ButtonBase}
  ${ColorVariant}
  ${FontVariant}
  ${Body02}
  padding: 8px 18px;
`

export const ButtonBig = styled.button<ButtonProps>`
  ${ButtonBase}
  ${ColorVariant}
  ${FontVariant}
  ${Body02}
  padding: 12px 30px;
`
