import { css } from 'styled-components'

export const DisplayExtraLarge = css`
  font-size: ${({ theme }) => theme.typography.displayExtraLarge.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.displayExtraLarge.fontWeight};
  line-height: ${({ theme }) => theme.typography.displayExtraLarge.lineHeight};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${({ theme }) =>
      theme.typography.displayExtraLarge[theme.breakpoints.sm]?.fontSize}px;
    line-height: ${({ theme }) =>
      theme.typography.displayExtraLarge[theme.breakpoints.sm]?.lineHeight};
  }
`

export const DisplayLarge = css`
  font-size: ${({ theme }) => theme.typography.displayLarge.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.displayLarge.fontWeight};
  line-height: ${({ theme }) => theme.typography.displayLarge.lineHeight};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${({ theme }) =>
      theme.typography.displayLarge[theme.breakpoints.sm]?.fontSize}px;
    line-height: ${({ theme }) =>
      theme.typography.displayLarge[theme.breakpoints.sm]?.lineHeight};
  }
`

export const DisplayMedium = css`
  font-size: ${({ theme }) => theme.typography.displayMedium.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.displayMedium.fontWeight};
  line-height: ${({ theme }) => theme.typography.displayMedium.lineHeight};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${({ theme }) =>
      theme.typography.displayMedium[theme.breakpoints.sm]?.fontSize}px;
    line-height: ${({ theme }) =>
      theme.typography.displayMedium[theme.breakpoints.sm]?.lineHeight};
  }
`

export const DisplaySmall = css`
  font-size: ${({ theme }) => theme.typography.displaySmall.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.displaySmall.fontWeight};
  line-height: ${({ theme }) => theme.typography.displaySmall.lineHeight};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${({ theme }) =>
      theme.typography.displaySmall[theme.breakpoints.sm]?.fontSize}px;
    line-height: ${({ theme }) =>
      theme.typography.displaySmall[theme.breakpoints.sm]?.lineHeight};
  }
`

export const Heading = css`
  font-size: ${({ theme }) => theme.typography.heading.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.heading.fontWeight};
  line-height: ${({ theme }) => theme.typography.heading.lineHeight};
`

export const Subheader = css`
  font-size: ${({ theme }) => theme.typography.subheader.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.subheader.fontWeight};
  line-height: ${({ theme }) => theme.typography.subheader.lineHeight};
  letter-spacing: 1px;
`

export const Body01 = css`
  font-size: ${({ theme }) => theme.typography.body01.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.body01.fontWeight};
  line-height: ${({ theme }) => theme.typography.body01.lineHeight};
`

export const Body02 = css`
  font-size: ${({ theme }) => theme.typography.body02.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.body02.fontWeight};
  line-height: ${({ theme }) => theme.typography.body02.lineHeight};
`

export const Caption = css`
  font-size: ${({ theme }) => theme.typography.caption.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.caption.fontWeight};
  line-height: ${({ theme }) => theme.typography.caption.lineHeight};
`
