import styled from 'styled-components'
import { SIDEBAR_WIDTH } from '../../components/sidebar/styled'
import SimpleTable from '../../components/simple-table'
import {
  Body01,
  Body02,
  Caption,
  DisplayLarge,
  DisplayMedium,
  DisplaySmall,
  Heading,
  Subheader,
} from '../../styles/shared'

export const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  height: 100%;

  h1 {
    ${DisplayLarge}
  }

  h2 {
    ${Heading}
  }

  h3 {
    ${DisplaySmall}
  }

  h4 {
    ${Subheader}
  }

  h5 {
    ${Body01}
  }

  h6 {
    ${Body02}
  }
`

export const ContentContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;
  grid-template-areas: 'sidebar main';

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`

export const Content = styled.main`
  padding-left: 80px;
  padding-right: 24px;
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
  max-width: 1140px;
  padding-top: 120px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 60px;
  grid-area: main;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-column: 1;
    padding-top: 140px;
  }
`

export const LeftColumn = styled.div`
  overflow: hidden;
`

export const RightColumn = styled.div`
  overflow: hidden;

  ${SimpleTable} {
    width: 100%;
  }
`

// Trying to set some global styling for OpenAPI
export const Section = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.palette.gray30};
  padding-bottom: 112px;
  margin-bottom: 40px;

  &:last-child {
    border-bottom: none;
  }
`
export const SectionWithoutBorder = styled.div`
  margin-bottom: 40px;
`

export const SectionTitle = styled.h1`
  ${DisplayLarge}
`

export const SubSectionTitle = styled.h2`
  ${DisplayMedium}
  display: flex;
`

export const SectionCaption = styled.span`
  ${Caption}
  color: ${({ theme }) => theme.palette.gray60};
  margin-left: 4px;
`

export const SectionSubtitle = styled.h2`
  ${DisplayMedium}
`
