import styled from 'styled-components'
import { Subheader } from '../../styles/shared'

export const SimpleTable = styled.table`
  background: ${({ theme }) => theme.palette.gray20};
  color: ${({ theme }) => theme.palette.gray80};
  border-collapse: collapse;

  thead th {
    ${Subheader}
    text-align: left;
    text-transform: uppercase;
    padding: 8px 16px;
    background: ${({ theme }) => theme.palette.gray30};
    vertical-align: top;
  }

  tbody td:first-child {
    font-weight: bold;
  }

  tbody td:nth-child(2) {
    width: 100%;
  }

  tbody tr {
    border-top: 1px solid ${({ theme }) => theme.palette.gray30};
  }

  tbody td {
    padding: 8px 16px;
    vertical-align: top;
  }
`
