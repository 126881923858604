import { useLocation } from '@reach/router'
import React, { useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchQuery } from '../../store/config'
import { removeTrailingSlash } from '../../utils/format'
import { Container, SearchInput, SearchIcon } from './styled'

const SearchBar: React.FC = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const query = useSelector((store) => store.config.searchQuery)
  const returnPageUrl = useRef('/introduction')
  const pathnameWithoutTrailingSlash = removeTrailingSlash(pathname)

  useEffect(() => {
    // Reset search on location change
    if (pathnameWithoutTrailingSlash !== '/search') {
      dispatch(setSearchQuery(''))
    }
  }, [dispatch, pathnameWithoutTrailingSlash])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(e.currentTarget.value))
    document.body.scrollTop = 0

    if (pathnameWithoutTrailingSlash !== '/search') {
      returnPageUrl.current = pathnameWithoutTrailingSlash
      void navigate(`/search`)
    }

    if (!e.currentTarget.value) {
      void navigate(returnPageUrl.current)
    }
  }

  return (
    <Container>
      <SearchIcon />
      <SearchInput type="search" onChange={handleChange} value={query} />
    </Container>
  )
}

export default SearchBar
