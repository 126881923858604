import styled from 'styled-components'
import SitooLogo from '../../assets/sitoo-dev-logo.svg'
import MenuIcon from '../../assets/menu.svg'
import CloseIcon from '../../assets/close.svg'

export const Container = styled.nav`
  background-color: ${({ theme }) => theme.palette.background.default};
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 66px;
  grid-gap: 0 16px;
  align-items: center;

  padding: 0 24px;
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.palette.gray30};

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-gap: 0;
  }
`

export const LogoContainer = styled.span`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  ${({ theme }) => theme.breakpoints.down('md')} {
    align-self: center;
    justify-self: center;
  }
`

export const Logo = styled(SitooLogo)``

export const MenuContainer = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: grid;
  }
`

export const CenterColumn = styled.div`
  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-column: 1 / 3;
    margin: 8px 0;
  }
`

export const RightColumn = styled.div`
  justify-self: end;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`

export const MenuButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  justify-self: start;
  display: grid;
  cursor: pointer;
`

export const OpenMenuIcon = styled(MenuIcon)`
  fill: ${({ theme }) => theme.palette.gray80};
`

export const CloseMenuIcon = styled(CloseIcon)``
