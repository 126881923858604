import { Link } from 'gatsby'
import styled from 'styled-components'
import { Body01 } from '../../../styles/shared'

export const PageLink = styled(Link)`
  ${Body01}
  color: ${({ theme }) => theme.palette.gray70};
  text-decoration: none;
  display: block;
  padding: 4px 0;

  &:hover,
  &.active {
    color: ${({ theme }) => theme.palette.blueBase};
  }
`
