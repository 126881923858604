import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import store, { RootState, Thunk, Dispatch } from '..'

export enum ThemeName {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface ConfigState {
  themeName: ThemeName
  isMenuOpen: boolean
  searchQuery: string
}

const initialState: ConfigState = {
  themeName: ThemeName.LIGHT,
  isMenuOpen: false,
  searchQuery: '',
}

// Slice
const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeName>) => {
      const { payload } = action
      state.themeName = payload
    },
    setMenuVisibility: (state, action: PayloadAction<boolean>) => {
      state.isMenuOpen = action.payload
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload
    },
  },
})

// Reducers
export default configSlice.reducer

// Selectors
export const configSelector = (state: RootState) => state.config

// Actions
const { setTheme, setMenuVisibility, setSearchQuery } = configSlice.actions

export { setMenuVisibility, setSearchQuery }

export const toggleMenu = () => {
  const { isMenuOpen } = store.getState().config
  return setMenuVisibility(!isMenuOpen)
}

// Thunks
export const toggleTheme = (): Thunk => (dispatch: Dispatch) => {
  const { themeName } = store.getState().config
  const name = themeName === ThemeName.LIGHT ? ThemeName.DARK : ThemeName.LIGHT

  dispatch(setTheme(name))
}
