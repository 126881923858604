import styled from 'styled-components'
import ArrowIcon from '../../assets/chevron-small-up.svg'
import Link from '../link'

export const StyledLink = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const RightArrow = styled(ArrowIcon)`
  fill: currentColor;
  transform: rotate(90deg);
  margin-left: 4px;
`
