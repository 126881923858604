import styled from 'styled-components'
import { Body01, Caption } from '../../styles/shared'
import Link from '../link'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: 0 -1px 0 0 ${({ theme }) => theme.palette.gray30};

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
  z-index: 20;
`

export const Nav = styled.nav`
  margin-bottom: 12px;
  margin-top: 5px;
`

export const NavLink = styled(Link)`
  ${Body01}
  display: inline-flex;
  margin: 0 10px;
  color: ${({ theme }) => theme.palette.gray70};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.palette.blueBase};
  }
`

export const Copyright = styled.div`
  ${Caption}
  color: ${({ theme }) => theme.palette.gray60};
`
